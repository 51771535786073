import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import firebase from 'firebase/app'
import _ from 'underscore'
import { Beforeunload } from 'react-beforeunload'

// APIs
import { getLastStockChangeResult, getStock, submitStockChange } from '../../../../backend'

// Types
import { RootState } from '../../../../store'
import { Product } from '../../../../protocol/firestore-v2/types'

//Utils
import catalogUtil from '../../../../util/catalog'

// Components
import { Button, Empty, message, Popconfirm, Spin, Tag, Space } from 'antd'

import './index.css'
import { Slot } from './slot'
import moment, { Moment } from 'moment'
import { Description } from './description'
import useRequest from '@ahooksjs/use-request'
import { LastStockEditResult } from './lastStockEditResult'
import tools from '../../../../util/tools'

interface SlotInfo {
  slotId: string
  productCode: string
  stock: number
  capacity: number
}
export interface SlotInfoWithProduct extends SlotInfo {
  product: Product | null
}

export interface SlotInfoWithProductWithImageUrl extends SlotInfoWithProduct {
  imageUrl: string
}
export interface DisplaySlotInfo {
  updateTimestamp: number
  slots: SlotInfoWithProductWithImageUrl[]
}

type SlotChanges = Record<string, SlotChange>

export interface SlotChange {
  from: number
  to: number
}

export type ProcessStatus = 'UNSET' | 'IDLE' | 'SUBMITTING'

function StockEdit(): JSX.Element {
  const project = useSelector((state: RootState) => state.app.project)
  const userinfo = useSelector((state: RootState) => state.app.userinfo)
  const { docId } = useParams<{ docId: string }>()
  const [changes, setChanges] = useState<SlotChanges>({})
  const [processStatus, setProcessStatus] = useState<ProcessStatus>('UNSET')
  const [latestStockChangeResultUpdateTime, setlatestStockChangeResultUpdateTime] = useState<Moment>()
  const [latestStockLevelUpdateTime, setlatestStockLevelUpdateTime] = useState<Moment>()
  const [currentDiffLatest, setCurrentDiffLatest] = useState<SlotInfoWithProductWithImageUrl[]>()
  const [saveLoading, setSaveLoading] = useState(false)
  const {
    data: slotInfo,
    error: stockLevelError,
    loading: stockLevelLoading,
    run: runGetLastestStockLevel
  } = useRequest(() => getLastestStockLevel(docId, project), {
    ready: project !== undefined && project !== '' && docId !== undefined
  })

  const getLastestChangeResult = async (_machineId: string, _project: string) => {
    const result = await getLastStockChangeResult({
      name: 'getLastStockChangeResult',
      project: _project,
      data: {
        machineId: _machineId
      }
    })
    setlatestStockChangeResultUpdateTime(moment())
    return result
  }

  const getLastestStockLevel = async (_machineId: string, _project: string) => {
    const slotsWithStockLevel = await getStock({
      name: 'getStock',
      data: {
        machineId: _machineId
      },
      project: project
    })
    const slotEntries = Object.entries(slotsWithStockLevel.data.latestStock.levelsBySlotId)
    const slotArray = slotEntries.map(([key, value]) => {
      return { slotId: key, ...value }
    })
    const products = await catalogUtil.getProductInfoByCodes(_.uniq(slotArray.map((slot) => slot.productCode)), project)
    const slotArrayWithProducts = slotArray.map((slot) => {
      return { ...slot, product: products.find((product) => slot.productCode === product.code) ?? null }
    })
    const getSlotInfoWithProductWithImageUrl = async (
      input: SlotInfoWithProduct[]
    ): Promise<SlotInfoWithProductWithImageUrl[]> => {
      const slotArrayWithIProductPromises = input.map(async (item) => {
        try {
          const imageUrl: string = await firebase.storage().ref(item?.product?.image?.path).getDownloadURL()
          return { ...item, imageUrl: imageUrl }
        } catch (error) {
          return { ...item, imageUrl: '' }
        }
      })
      return await Promise.all(slotArrayWithIProductPromises)
    }
    setlatestStockLevelUpdateTime(moment())
    return {
      updateTimestamp: slotsWithStockLevel.data.latestStock.timestamp,
      slots: await getSlotInfoWithProductWithImageUrl(slotArrayWithProducts)
    }
  }

  const {
    data: latestStockChangeResult,
    error: latestStockChangeResultError,
    loading: latestStockChangeResultLoading,
    run: runGetLastestChangeResult
  } = useRequest(() => getLastestChangeResult(docId, project), {
    ready: project !== undefined && docId !== undefined && project !== '',
    pollingInterval: 3000,
    pollingWhenHidden: false
  })

  const allFull = () => {
    if (!slotInfo) {
      return
    }
    let newChanges = {}
    slotInfo.slots.forEach((slot) => {
      newChanges = {
        ...newChanges,
        ...{
          [slot.slotId]: {
            from: slot.stock,
            to: slot.capacity
          }
        }
      }
    })
    setChanges({ ...newChanges })
  }

  const allEmpty = () => {
    if (!slotInfo) {
      return
    }
    let newChanges = {}
    slotInfo.slots.forEach((slot) => {
      newChanges = {
        ...newChanges,
        ...{
          [slot.slotId]: {
            from: slot.stock,
            to: 0
          }
        }
      }
    })
    setChanges({ ...newChanges })
  }

  const allReset = () => {
    setChanges({})
  }

  const isUntouchable = (): boolean => {
    if (processStatus === 'SUBMITTING') {
      return true
    }
    return false
  }

  const saveChanges = async () => {
    if (!changes) {
      return
    }
    try {
      if (!slotInfo) {
        throw new Error('Latest stock infomation cannot be loaded.')
      }
      setSaveLoading(true)
      const latestStockLevel = await getLastestStockLevel(docId, project)
      const diff = tools.findDiff(latestStockLevel.slots, slotInfo.slots)
      const diffHasChanges = diff.filter((slot) => changes[slot.slotId] && changes[slot.slotId].from !== slot.stock)
      if (diffHasChanges.length > 0) {
        setCurrentDiffLatest(diffHasChanges)
        runGetLastestStockLevel()
        setSaveLoading(false)
        message.error('Some stock qunatity of slots have already been changed.').then(
          () => {},
          () => {}
        )
        return
      }
      const response = await submitStockChange({
        name: 'submitStockChange',
        project: project,
        data: {
          machineId: docId,
          changesBySlotId: changes
        }
      })
      if (!response.data.requestTimestamp) {
        throw new Error('Unknown error')
      }
      setSaveLoading(false)
      message.info('Request submitted. Please wait a moment.')
      window.scrollTo(0, 0)
      setChanges({})
      runGetLastestChangeResult()
    } catch (error) {
      console.error(error)
      message.error(`Fail to submit.${error instanceof Error ? error.message : 'Unknown error'}`)
      setSaveLoading(false)
    }
  }

  const updateChanges = (slotId: string, stock: number) => {
    if (!slotInfo) {
      return
    }
    const origStock = slotInfo.slots.find((slot) => slot.slotId === slotId)!.stock
    if (origStock !== stock) {
      setChanges((prev) => {
        if (!prev) {
          return { [slotId]: { from: origStock, to: stock } }
        }
        return { ...prev, [slotId]: { from: origStock, to: stock } }
      })
    } else {
      setChanges((prev) => {
        const newState = { ...prev }
        delete newState[slotId]
        return { ...newState }
      })
    }
  }

  useEffect(() => {
    if (!latestStockChangeResult) {
      return
    }
    if (latestStockChangeResult.data.result === 'PENDING') {
      setProcessStatus('SUBMITTING')
      return
    }
    if (
      latestStockChangeResult.data.result === 'SUCCESS' ||
      latestStockChangeResult.data.result === 'FAIL' ||
      latestStockChangeResult.data.result === 'EXPIRED' ||
      latestStockChangeResult.data.result === 'NO_REQUEST'
    ) {
      setProcessStatus('IDLE')
      return
    }
  }, [latestStockChangeResult])

  useEffect(() => {
    if (latestStockChangeResult && latestStockChangeResult.data.result === 'SUCCESS') {
      if (
        !latestStockLevelUpdateTime ||
        moment.unix(latestStockChangeResult.data.completeTimestamp) >= latestStockLevelUpdateTime
      ) {
        runGetLastestStockLevel()
        setCurrentDiffLatest(undefined)
        setChanges({})
      }
    }
  }, [latestStockChangeResult, latestStockLevelUpdateTime])

  const getChange = (slotId: string): SlotChange | null => {
    try {
      return changes[slotId]
    } catch (error) {
      return null
    }
  }

  const showLastStockChangeResultBar = useCallback((): boolean => {
    if (
      latestStockChangeResult &&
      latestStockChangeResult.data.result !== 'NO_REQUEST' &&
      latestStockChangeResult.data.requestUser.email === userinfo?.userBasicInfo?.email &&
      moment().diff(moment.unix(latestStockChangeResult.data.requestTimestamp), 's') < 10800
    ) {
      return true
    }
    return false
  }, [latestStockChangeResult])

  return (
    <div className='page-stock-edit all_nowarp noClick'>
      {changes && Object.keys(changes).length > 0 && (
        <Beforeunload
          onBeforeunload={(e: Event) => {
            e.preventDefault()
          }}
        />
      )}
      <div className='title-bar'>
        <div className='title'>{docId}</div>
      </div>
      <div className='page-stock-edit-content'>
        {/* <div className='subtitle'> Last Request Result </div> */}
        {showLastStockChangeResultBar() && (
          <LastStockEditResult
            data={latestStockChangeResult}
            error={latestStockChangeResultError}
            loading={latestStockChangeResultLoading}
            timestamp={latestStockChangeResultUpdateTime}
          />
        )}

        {(!slotInfo || slotInfo.slots.length < 1) && !stockLevelLoading ? (
          <Empty
            className='slotsContainer'
            style={{ display: 'flex', placeItems: 'center', justifyContent: 'center', background: '#f5f5f58a' }}
            description={<span style={{ color: 'grey' }}>No stock information uploaded</span>}></Empty>
        ) : (
          <>
            {/* <div className='subtitle'> Change stock quantity </div> */}
            <Description
              processStatus={processStatus}
              slotInfo={slotInfo}
              latestStockChangeResult={latestStockChangeResult}
              diff={currentDiffLatest}
            />
            <Spin
              spinning={
                (latestStockChangeResult && latestStockChangeResult.data.result === 'PENDING') || stockLevelLoading
              }
              size='large'>
              <div className='slotsContainer'>
                {slotInfo && latestStockLevelUpdateTime && (
                  <div className='lastUpdatetimeTag'>
                    <Space>
                      <strong>Last uploaded time</strong>
                      <Tag>{moment.unix(slotInfo?.updateTimestamp).format('YYYY/MM/DD HH:mm:ss')}</Tag>
                    </Space>
                    <Space>
                      <strong>Last update time</strong>
                      <Tag>{latestStockLevelUpdateTime.format('YYYY/MM/DD HH:mm:ss')}</Tag>
                    </Space>
                  </div>
                )}
                <div className='slots'>
                  {slotInfo &&
                    slotInfo.slots
                      .sort((a, b) => {
                        return a.slotId.localeCompare(b.slotId, undefined, { numeric: true, sensitivity: 'base' })
                      })
                      .map((slot) => (
                        <React.Fragment key={slot.slotId}>
                          <Slot
                            slot={slot}
                            updateChanges={updateChanges}
                            getChange={getChange}
                            disabled={isUntouchable()}
                            warning={
                              currentDiffLatest !== undefined &&
                              currentDiffLatest.find((foundSlot) => foundSlot.slotId === slot.slotId) !== undefined
                            }
                          />
                        </React.Fragment>
                      ))}
                </div>
              </div>
            </Spin>

            <div className='actions'>
              <Popconfirm
                okText='Confirm'
                title='It will overide all your current changes. Are you sure to reset all slots?'
                onConfirm={allReset}
                disabled={isUntouchable() || Object.keys(changes).length < 1}>
                <Button disabled={isUntouchable() || Object.keys(changes).length < 1} type='default'>
                  Reset all slots
                </Button>
              </Popconfirm>
              <Popconfirm
                okText='Confirm'
                title='It will overide all your current changes. Are you sure to empty out all slots?'
                onConfirm={allEmpty}
                disabled={isUntouchable()}>
                <Button disabled={isUntouchable()} type='default'>
                  Empty out all Slots
                </Button>
              </Popconfirm>
              <Popconfirm
                okText='Confirm'
                title='It will overide all your current changes. Are you sure to fill up all slots?'
                onConfirm={allFull}
                disabled={isUntouchable()}>
                <Button disabled={isUntouchable()} type='default'>
                  Fill up all slots
                </Button>
              </Popconfirm>
              <Popconfirm
                okText='Confirm'
                title='Request cannot be intercepted after it was sent. Are you sure to save changes?'
                onConfirm={saveChanges}
                disabled={Object.keys(changes).length < 1 || isUntouchable()}>
                <Button
                  type='primary'
                  loading={saveLoading}
                  disabled={Object.keys(changes).length < 1 || isUntouchable()}>
                  Save
                </Button>
              </Popconfirm>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default StockEdit
