import { Provider } from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// Need to import CSS first before Router, so that CSS used in Router has higher priority
import './App.css'

import store, { rrfProps } from './store'
import Router from './router'

function App (): JSX.Element {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <Router />
        </ReactReduxFirebaseProvider>
      </DndProvider>
    </Provider>
  )
}

export default App
