import { Alert } from 'antd'
import './index.css'
import { DisplaySlotInfo, ProcessStatus, SlotInfoWithProductWithImageUrl } from '.'
import { HttpsCallableResult } from '../../../../backend'
import { GetLastStockChangeResultApiResponse } from '../../../../protocol/functions-v2/console-api/types'
interface DescriptionProps {
  isReadOnly?: boolean
  slotInfo?: DisplaySlotInfo
  processStatus: ProcessStatus
  latestStockChangeResult: HttpsCallableResult<GetLastStockChangeResultApiResponse> | undefined
  diff?: SlotInfoWithProductWithImageUrl[] | undefined
}

export const Description = (props: DescriptionProps): JSX.Element => {
  if (props.latestStockChangeResult && props.latestStockChangeResult.data.result === 'PENDING') {
    return (
      <Alert
        message='This function is not available for this machine until the last request is completed'
        banner
        style={{ marginBottom: '1.5vh' }}
      />
    )
  }

  if (props.diff) {
    return (
      <Alert
        className='component-description-warning'
        message={
          <ul>
            <li>Some stock quantity of slots have already been changed before you sent the request.</li>
            <li>Those slots has already been highlighted below and reset the changes.</li>
            <li>Please check and revise the changes before you submit the request again.</li>
          </ul>
        }
        style={{ marginBottom: '1.5vh' }}
      />
    )
  }
  return (
    <Alert
      className='component-description-info'
      // showIcon
      message={
        <ul>
          {!props.isReadOnly && props.slotInfo && props.slotInfo.slots.length > 0 && (
            <>
              <li>Changes will take a while to be effective.</li>
              <li>Request can not be intercepted after it was sent. Please ensure your changes are correct.</li>
              <li>
                Changes will be failed due to a variety of reasons. Please check the save results carefully after the
                request is completed.
              </li>
            </>
          )}
          {props.slotInfo && props.slotInfo.slots.length < 1 && (
            <>
              <li>Stock Infomration has never been uploaded by the machine.</li>
            </>
          )}

          {props.isReadOnly && (
            <li>
              This module is currently in <strong>read-only</strong> mode.
            </li>
          )}
        </ul>
      }
    />
  )
}
