import { Link } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import React from 'react'
import { RootState } from '../../store'
import { connect } from 'react-redux'
import { pathToRegexp } from 'path-to-regexp'

import './index.css'

type Props = ReturnType<typeof mapState> & {
  location: Location
  project: string
}

interface BreadCrumbMap {
  [path: string]: string
}

const breadcrumbNameMap: BreadCrumbMap = {
  '/:project/home': 'Home',
  '/:project/product': 'Product',
  '/:project/product/create': 'Product Create',
  '/:project/product/:status/:itemCode': 'Product Detail',
  '/:project/product/:status/:itemCode/edit': 'Product Edit',
  '/:project/machine': 'Machine',
  '/:project/machine/create': 'Machine Create',
  '/:project/machine/:docId': 'Machine Detail',
  '/:project/machine/:docId/playlist': 'Change Playlist',
  '/:project/machine/:docId/catalog': 'Change Catalog',
  '/:project/machine/:docId/stock': 'Change Stock Quantity',
  '/:project/machine/:docId/edit': 'Machine Edit',
  '/:project/media-list': 'Media List',
  '/:project/media-list/create': 'Media Create',
  '/:project/media-list/:status/:id': 'Media Detail',
  '/:project/media-list/:status/:id/edit': 'Media Edit',
  '/:project/media-tools': 'Media Tools',
  '/:project/machine-transaction': 'Machine Transaction',
  '/:project/machine-transaction/:docId': 'Machine Transaction Detail',
  '/:project/daily-transaction': 'Daily Transaction',
  '/:project/daily-transaction/:docId': 'Daily Transaction Detail'
}

function BreadCom (props: Props): JSX.Element {
  const { location: locationProp } = props
  const pathSnippets = locationProp.pathname.split('/').filter((i) => i)
  const extraBreadcrumbItems: any[] = []
  pathSnippets.forEach((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    for (const item of Object.keys(breadcrumbNameMap)) {
      if (pathToRegexp(item).test(url)) {
        extraBreadcrumbItems.push(
          <Breadcrumb.Item key={url}>
            <Link to={url}>{breadcrumbNameMap[item]}</Link>
          </Breadcrumb.Item>
        )
        break
      }
    }
  })
  const breadcrumbItems = [
    <Breadcrumb.Item key='/'>
      <Link to='/'>{props.project}</Link>
    </Breadcrumb.Item>
  ].concat(extraBreadcrumbItems)
  return (
    <div className='bread'>
      <Breadcrumb separator='>'>{breadcrumbItems}</Breadcrumb>
    </div>
  )
}

const mapState = (state: RootState) => ({
  project: state.app.project
}) as const

export default connect(mapState)(BreadCom)
