import './index.css'
import { HttpsCallableResult } from '../../../../backend'
import { GetLastStockChangeResultApiResponse } from '../../../../protocol/functions-v2/console-api/types'
import { Button, Collapse, Spin, Tag, Timeline } from 'antd'
import { Moment } from 'moment'
import { ReactNode, useEffect, useState } from 'react'
import moment from 'moment'
import {
  CheckCircleFilled,
  LoadingOutlined,
  QuestionCircleFilled,
  CloseCircleFilled,
  StopFilled
} from '@ant-design/icons'
const { Panel } = Collapse

type Props = {
  data?: HttpsCallableResult<GetLastStockChangeResultApiResponse>
  error?: Error
  loading: boolean
  timestamp?: Moment
}

type TimelineObj = {
  status: 'SUCCESS' | 'NORMAL' | 'LOADING' | 'ERROR'
  timestamp?: number
  actionName: string
}

type Statues = 'NO_REQUEST' | 'PENDING' | 'SUCCESS' | 'FAIL' | 'EXPIRED' | undefined

const getClass = (changeStatus: Statues): string => {
  if (changeStatus === 'PENDING' || changeStatus === 'NO_REQUEST') {
    return ' ' + 'normal'
  }
  if (changeStatus === 'FAIL' || changeStatus === 'EXPIRED') {
    return ' ' + 'error'
  }
  if (changeStatus === 'SUCCESS') {
    return ' ' + 'success'
  }
  return ''
}

const statusTransform = (input: GetLastStockChangeResultApiResponse | undefined): ReactNode => {
  if (input?.result === 'EXPIRED') {
    return 'Request is expired (No response from machine).'
  }
  if (input?.result === 'PENDING') {
    return <> Processing previous request. Request will be expired at <span className='timestamp'> {moment.unix(input.expireTimestamp).format('YYYY/MM/DD HH:mm:ss')}</span></>
  }
  if (input?.result === 'FAIL') {
    return 'Request failed.'
  }
  if (input?.result === 'NO_REQUEST') {
    return 'No request found.'
  }
  if (input?.result === 'SUCCESS') {
    return 'Changes have been successfully applied.'
  }
  return 'Unknown'
}

const getIcon = (input: Statues) => {
  if (input === 'PENDING') {
    return <LoadingOutlined style={{ marginRight: '0.8vw' }} />
  }
  if (input === 'NO_REQUEST') {
    return <StopFilled style={{ marginRight: '0.8vw' }} />
  }
  if (input === 'EXPIRED' || input === 'FAIL') {
    return <CloseCircleFilled style={{ marginRight: '0.8vw' }} />
  }
  if (input === 'SUCCESS') {
    return <CheckCircleFilled style={{ marginRight: '0.8vw' }} />
  }
  return <QuestionCircleFilled style={{ marginRight: '0.8vw' }} />
}

const getColor = (input: TimelineObj['status']): string => {
  switch (input) {
    case 'SUCCESS':
      return 'green'
    case 'ERROR':
      return 'red'
    case 'LOADING':
      return 'gray'
    default:
      return 'blue'
  }
}

export function LastStockEditResult({ data, error, loading, timestamp: time }: Props) {
  const [timeline, setTimeline] = useState<Array<TimelineObj>>()

  useEffect(() => {
    if (data?.data.result === 'SUCCESS') {
      setTimeline([
        { status: 'NORMAL', timestamp: data.data.requestTimestamp, actionName: 'Request sent' },
        { status: 'NORMAL', timestamp: data.data.startTimestamp, actionName: 'Machine received the request' },
        { status: 'SUCCESS', timestamp: data.data.completeTimestamp, actionName: 'Request completed' }
      ])
      return
    }
    if (data?.data.result === 'FAIL') {
      const timelineItems: Array<TimelineObj> = [
        { status: 'NORMAL', timestamp: data.data.requestTimestamp, actionName: 'Request sent' }
      ]
      if (data.data.startTimestamp) {
        timelineItems.push({
          status: 'NORMAL',
          timestamp: data.data.startTimestamp,
          actionName: 'Machine received the request'
        })
      }
      timelineItems.push({
        status: 'ERROR',
        timestamp: data.data.endTimestamp ?? undefined,
        actionName: `Request Failed (${data.data.error.message ?? data.data.error.code})`
      })
      setTimeline(timelineItems)
      return
    }
    if (data?.data.result === 'EXPIRED') {
      setTimeline([
        { status: 'NORMAL', timestamp: data.data.requestTimestamp, actionName: 'Request sent' },
        { status: 'ERROR', timestamp: data.data.expireTimestamp, actionName: 'Request Expired' }
      ])
      return
    }
    if (data?.data.result === 'PENDING') {
      setTimeline([
        { status: 'NORMAL', timestamp: data.data.requestTimestamp, actionName: 'Request sent' }
      ])
    }
  }, [data])
  return (
    <div className={'component-last-stock-edit-result' + getClass(data?.data.result)}>
      <Spin spinning={loading} delay={500} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
        <Collapse
          destroyInactivePanel={true}
          expandIconPosition='right'
          collapsible={timeline === undefined || timeline.length < 1 ? 'disabled' : undefined}>
          <Panel
            showArrow={false}
            extra={
              <Button size='small' disabled={timeline === undefined || timeline.length < 1}>
                Detail
              </Button>
            }
            header={
              <div className='statusHeader'>
                <div>
                  {getIcon(data?.data?.result)}
                  {statusTransform(data?.data)}
                </div>
                {data?.data?.result !== 'NO_REQUEST' && data?.data.requestTimestamp && (
                  <div>
                    <strong>Requested </strong>: {moment.unix(data.data.requestTimestamp).fromNow()}
                  </div>
                )}
              </div>
            }
            key='1'>
            {timeline && (
              <Timeline className='timeline' mode='left' pending={data?.data.result === 'PENDING' ? 'Waiting for response from machine' : undefined}>
                {timeline.map((item) => (
                  <Timeline.Item
                    key={item.actionName}
                    // label={item.timestamp ? moment.unix(item.timestamp).format('YYYY/MM/DD HH:mm:ss') : undefined}
                    color={getColor(item.status)}>
                    {item.actionName} {item.timestamp && <>at <span className='timestamp'> {moment.unix(item.timestamp).format('YYYY/MM/DD HH:mm:ss')}</span></>}
                  </Timeline.Item>
                ))}
              </Timeline>
            )}
          </Panel>
        </Collapse>
      </Spin>
    </div>
  )
}
