export const menu = [
  {
    id: 1,
    title: 'Home',
    icon: 'icon-home',
    url: '/home',
    parent: null,
    desc: 'Home',
    sorts: 0,
    status: 1,
  },
  {
    id: 2,
    title: 'Machine',
    icon: 'icon-appstore',
    url: '/machine',
    code: 'machine.read',
    parent: null,
    desc: 'Machine',
    sorts: 1,
    status: 1,
  },
  {
    id: 3,
    title: 'Product',
    icon: 'icon-shopping',
    url: '/product',
    code: 'product.read',
    parent: null,
    desc: 'Product',
    sorts: 1,
    status: 1,
  },
  {
    id: 4,
    title: 'Media',
    icon: 'icon-rocket',
    url: '/media',
    code: 'media.read',
    parent: null,
    desc: 'Media',
    sorts: 1,
    status: 1,
  },
  {
    id: 5,
    title: 'Media List',
    url: '/media-list',
    code: 'media.read',
    parent: 4,
    desc: 'Media List',
    sorts: 1,
    status: 1,
  },
  {
    id: 6,
    title: 'Tools',
    url: '/media-tools',
    code: 'media.read',
    parent: 4,
    desc: 'Tools',
    sorts: 1,
    status: 1,
  },
  {
    id: 7,
    title: 'Transaction',
    icon: 'icon-Dollar',
    url: '/transaction',
    code: 'transaction.read',
    desc: 'Transaction',
    parent: null,
    sorts: 1,
    status: 1,
  },
  {
    id: 8,
    title: 'Machine Transaction',
    url: '/machine-transaction',
    code: 'transaction.read',
    desc: 'Machine Transaction',
    parent: 7,
    sorts: 1,
    status: 1,
  },
  {
    id: 9,
    title: 'Daily Transaction',
    url: '/daily-transaction',
    code: 'transaction.read',
    desc: 'Daily Transaction',
    parent: 7,
    sorts: 1,
    status: 1,
  }
]



// export const power = [
//   {
//     id: 1,
//     menu: 3,
//     title: 'Create',
//     code: 'user:add',
//     desc: 'User - Create User',
//     sorts: 1,
//     status: 1
//   },
//   {
//     id: 2,
//     menu: 3,
//     title: 'Edit',
//     code: 'user:up',
//     desc: 'User - Edit User',
//     sorts: 2,
//     status: 1
//   },
//   {
//     id: 3,
//     menu: 3,
//     title: 'View',
//     code: 'user:query',
//     desc: 'User - View User',
//     sorts: 3,
//     status: 1
//   },
//   {
//     id: 4,
//     menu: 3,
//     title: 'Delete',
//     code: 'user:del',
//     desc: 'User - Delete User',
//     sorts: 4,
//     status: 1
//   },
//   {
//     id: 5,
//     menu: 3,
//     title: 'Change Role',
//     code: 'user:role',
//     desc: 'User - Change Role',
//     sorts: 5,
//     status: 1
//   },

//   {
//     id: 6,
//     menu: 4,
//     title: 'Create',
//     code: 'role:add',
//     desc: 'Role - Create Power',
//     sorts: 1,
//     status: 1
//   },
//   {
//     id: 7,
//     menu: 4,
//     title: 'Edit',
//     code: 'role:up',
//     desc: 'Role - Edit Role',
//     sorts: 2,
//     status: 1
//   },
//   {
//     id: 8,
//     menu: 4,
//     title: 'View',
//     code: 'role:query',
//     desc: 'Role - View Role',
//     sorts: 3,
//     status: 1
//   },
//   {
//     id: 18,
//     menu: 4,
//     title: 'Change Power',
//     code: 'role:power',
//     desc: 'Role - Change Power',
//     sorts: 4,
//     status: 1
//   },
//   {
//     id: 9,
//     menu: 4,
//     title: 'Delete',
//     code: 'role:del',
//     desc: 'Role - Delete Role',
//     sorts: 5,
//     status: 1
//   },

//   {
//     id: 10,
//     menu: 5,
//     title: 'Create',
//     code: 'power:add',
//     desc: 'Power - Create Power',
//     sorts: 1,
//     status: 1
//   },
//   {
//     id: 11,
//     menu: 5,
//     title: 'Edit',
//     code: 'power:up',
//     desc: 'Power - Edit Role',
//     sorts: 2,
//     status: 1
//   },
//   {
//     id: 12,
//     menu: 5,
//     title: 'View',
//     code: 'power:query',
//     desc: 'Power - View Power',
//     sorts: 3,
//     status: 1
//   },
//   {
//     id: 13,
//     menu: 5,
//     title: 'Delete',
//     code: 'power:del',
//     desc: 'Power - Delete Power',
//     sorts: 2,
//     status: 1
//   },

//   {
//     id: 14,
//     menu: 6,
//     title: 'Create',
//     code: 'menu:add',
//     desc: 'Menu - Create Menu',
//     sorts: 1,
//     status: 1
//   },
//   {
//     id: 15,
//     menu: 6,
//     title: 'Edit',
//     code: 'menu:up',
//     desc: 'Menu - Edit Menu',
//     sorts: 2,
//     status: 1
//   },
//   {
//     id: 16,
//     menu: 6,
//     title: 'View',
//     code: 'menu:query',
//     desc: 'Menu - View Menu',
//     sorts: 3,
//     status: 1
//   },
//   {
//     id: 17,
//     menu: 6,
//     title: 'Delete',
//     code: 'menu:del',
//     desc: 'Menu - Delete Menu',
//     sorts: 2,
//     status: 1
//   }
// ]
