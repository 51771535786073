/** 根路由 **/

// ==================
// Library
// ==================
import { useEffect, useCallback } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
// import {createBrowserHistory as createHistory} from "history"; 
import { createHashHistory as createHistory } from 'history' 
import { message } from 'antd'
// import { useFirebase } from 'react-redux-firebase'

// ==================
// Customization
// ==================
import tools from '../util/tools'

// ==================
// Component
// ==================
import BasicLayout from '../layouts/BasicLayout'
import UserLayout from '../layouts/UserLayout'

// ==================
// Types declaration
// ==================
import { RootState, Dispatch } from '../store'

// 全局提示只显示2秒
message.config({
  duration: 2
})

const routerHistory = createHistory()

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

// ==================
// Component
// ==================
function RouterCom (props: Props): JSX.Element {
  useEffect(() => {
    const userinfo = sessionStorage.getItem('userinfo')
    const project = localStorage.getItem('project')
    /**
     * sessionStorage中有user信息，但store中没有
     * 说明刷新了页面，需要重新同步user数据到store
     * **/
    if (userinfo != null && props.userinfo?.userBasicInfo == null) {
      // TODO Re-enable rule
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      props.setUserInfo(JSON.parse(tools.uncompile(userinfo)))
    }
    if (project != null) {
      // TODO Re-enable rule
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      props.setProject(project)
    }
  }, [props])

  /** Trigger before every redirection **/
  const onEnter = useCallback((Component, prop) => {
    /** Redirect to login page if userinfo is not found */
    const userinfo = sessionStorage.getItem('userinfo')
    // const project = localStorage.getItem('project')

    if (userinfo != null) {
      return <Component {...prop} />
    }
    return <Redirect to='/user/login' />
  }, [])

  return (
    <Router history={routerHistory}>
      <Route
        render={(): JSX.Element => {
          return (
            <Switch>
              <Route path='/user' component={UserLayout} />
              <Route
                path='/'
                render={(layoutProps): JSX.Element => onEnter(BasicLayout, layoutProps)}
              />
            </Switch>
          )
        }}
      />
    </Router>
  )
}

const mapState = (state: RootState) => ({
  profile: state.firebase.profile,
  userinfo: state.app.userinfo,
  project: state.app.project
}) as const
const mapDispatch = (dispatch: Dispatch) => ({
  setUserInfo: dispatch.app.setUserInfo,
  setProject: dispatch.app.setProject
}) as const

// TODO Re-enable rule and fix circular reference
// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
// @ts-ignore
export default connect(mapState, mapDispatch)(RouterCom)
