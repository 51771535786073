/** Header **/

// ==================
// Library
// ==================
import { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout, Tooltip, Menu, Dropdown, Avatar, Select, Alert } from 'antd'
import { useFirebase } from 'react-redux-firebase'
import { History as BrowseHistory } from 'history'

import { MenuFoldOutlined, FullscreenOutlined, FullscreenExitOutlined, LogoutOutlined } from '@ant-design/icons'

// ==================
// Customization
// ==================
import './index.css'

// ==================
// Types declaration
// ==================
import { UserInfo } from '../../models/index.type'
import { RootState, Dispatch } from '../../store'

const { Option: SelectOption } = Select

const { Header } = Layout

interface Element {
  webkitRequestFullscreen?: () => void
  webkitExitFullscreen?: () => void
  mozRequestFullScreen?: () => void
  mozCancelFullScreen?: () => void
  msRequestFullscreen?: () => void
  msExitFullscreen?: () => void
}

type Props = ReturnType<typeof mapState> &
ReturnType<typeof mapDispatch> & {
  collapsed: boolean // 菜单的状态
  userinfo: UserInfo // 用户信息
  // TODO Review if this prop is still needed
  // eslint-disable-next-line react/no-unused-prop-types
  location: Location
  history: BrowseHistory
  onToggle: () => void // 菜单收起与展开状态切换
  onLogout: () => void // Logout
}

function HeaderCom(props: Props): JSX.Element {
  const [fullScreen, setFullScreen] = useState(false)

  const firebase = useFirebase()
  // Enter Fullscreen
  const requestFullScreen = useCallback(() => {
    const element: HTMLElement & Element = document.documentElement
    const requestMethod =
      element.requestFullscreen ?? // W3C
      element.webkitRequestFullscreen ?? // Chrome
      element.mozRequestFullScreen ?? // FireFox
      element.msRequestFullscreen // IE11
    if (requestMethod != null) {
      // TODO Re-enable rule
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      requestMethod.call(element)
    }
    setFullScreen(true)
  }, [])

  // Exit Fullscreen
  const exitFullScreen = useCallback(() => {
    const element: Document & Element = document
    const exitMethod =
      element.exitFullscreen ?? // W3C
      element.mozCancelFullScreen ?? // firefox
      element.webkitExitFullscreen ?? // Chrome等
      element.msExitFullscreen // IE11

    if (exitMethod != null) {
      // TODO Re-enable rule
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      exitMethod.call(document)
    }
    setFullScreen(false)
  }, [])

  // Logout
  const onMenuClick = useCallback(
    async (e) => {
      /** Logout button on click */
      if (e.key === 'logout') {
        await firebase.logout()
        await props.onLogout()
      }
    },
    [props, firebase]
  )

  const projectSwitch = (value: string): void => {
    // Back to home page once project switched
    props.history.push(`/${value}/home`)
    sessionStorage.setItem('project', value)
    // TODO Re-enable rule
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    props.setProject(value)
  }

  return (
    <Header className='header'>
      <Tooltip placement='bottom' title={props.collapsed ? 'Expand Menu' : 'Hide Menu'}>
        <MenuFoldOutlined className={props.collapsed ? 'trigger fold' : 'trigger'} onClick={() => props.onToggle()} />
      </Tooltip>
      {process.env.REACT_APP_ENV === 'dev' && (
        <Alert className='underDevelopmentWarn' message='Development Mode' type='info' showIcon />
      )}
      <div className='rightBox'>
        <Tooltip placement='bottom' title={fullScreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}>
          <div className='full all_center'>
            {fullScreen ? (
              <FullscreenExitOutlined className='icon' onClick={exitFullScreen} />
            ) : (
              <FullscreenOutlined className='icon' onClick={requestFullScreen} />
            )}
          </div>
        </Tooltip>

        {props.userinfo?.userBasicInfo ? (
          <>
            <Select
              className='projectSelector'
              defaultValue={props.project}
              onChange={projectSwitch}
              style={{ minWidth: 150 }}
            >
              {props.userinfo.projectNames && props.userinfo.projectNames.map((project) => {
                return (
                  <SelectOption value={project} key={project}>
                    {project}
                  </SelectOption>
                )
              })}
            </Select>
            <Dropdown
              overlay={
                <Menu className='menu' selectedKeys={[]} onClick={onMenuClick}>
                  <Menu.Item key='logout'>
                    <LogoutOutlined />
                    Logout
                  </Menu.Item>
                </Menu>
              }
              placement='bottomRight'
            >
              <div className='userhead all_center'>
                {typeof props.userinfo.userBasicInfo.photoURL !== 'undefined' &&
                props.userinfo.userBasicInfo.photoURL !== '' &&
                props.userinfo.userBasicInfo.photoURL !== null ? (
                  <Avatar src={props.userinfo.userBasicInfo.photoURL} />
                    ) : (
                  <Avatar className='avatarWithText'>
                    {
                      (props.userinfo.userBasicInfo.displayName ?? props.userinfo.userBasicInfo.email)
                        .charAt(0)
                        .toUpperCase()
                    }
                  </Avatar>
                    )}
                <span className='username'>
                  {props.userinfo.userBasicInfo.displayName ?? props.userinfo.userBasicInfo.email}
                </span>
              </div>
            </Dropdown>
          </>
        ) : (
          <Tooltip placement='bottom' title='Click to login'>
            <div className='full all_center'>
              <Link to='/user/login'>guest</Link>
            </div>
          </Tooltip>
        )}
      </div>
    </Header>
  )
}

const mapState = (state: RootState) =>
  ({
    firebase: state.firebase,
    userinfo: state.app.userinfo,
    project: state.app.project,
  } as const)

const mapDispatch = (dispatch: Dispatch) =>
  ({
    setProject: dispatch.app.setProject,
  } as const)

// TODO Re-enable rule and fix circular reference
// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
// @ts-ignore
export default connect(mapState, mapDispatch)(HeaderCom)
