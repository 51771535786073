/**
 * 子Component有任何报错都会传递到此
 * 用于页面异步加载出错时显示
 * 此Component只能用class的方式，因为hooks不支持getDerivedStateFromError 和 componentDidCatch
 */
import React from 'react'
import { WarningOutlined } from '@ant-design/icons'
import './index.css'

interface Props {
  location: Location
  children: JSX.Element
}
interface State {
  hasError: boolean
}
export default class ErrorBoundary extends React.PureComponent<Props, State> {
  constructor (props: Props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError (): State {
    return { hasError: true }
  }

  componentDidUpdate (prevP: Props): void {
    if (prevP.location !== this.props.location) {
      // TODO Fix do not use setState in componentDidUpdate
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasError: false
      })
    }
  }

  render (): JSX.Element & React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className='error-boundary'>
          <WarningOutlined className='error-icon' />
          <div>Fail to load. Please refresh the page.</div>
        </div>
      )
    }
    return this.props.children
  }
}
