import type { CatalogSaveInput, DraftCatalog, DraftCatalogSaveInput } from '../protocol/functions-v2/console-api/types'
import { CatalogTray, Product, Slot } from '../types'
import _ from 'underscore'
import firebase from 'firebase/app'
import 'firebase/firestore'

const getRandomNumId = (): string => {
  return Math.floor(Math.random() * 1000000000).toString()
}

const catalogUtil = {
  getRandomNumId(): string {
    return Math.floor(Math.random() * 1000000000).toString()
  },
  sort(input: Slot[]): Slot[] {
    const sortedInput = input
    sortedInput.sort((a, b) => {
      return a.slotId.localeCompare(b.slotId, undefined, { numeric: true, sensitivity: 'base' })
    })
    return sortedInput
  },
  sortAndTransformToTrays(input: Slot[]): CatalogTray[] {
    const newTrays: CatalogTray[] = []
    const sortedInput = input
    sortedInput.sort((a, b) => {
      return a.slotId.localeCompare(b.slotId, undefined, { numeric: true, sensitivity: 'base' })
    })
    const traysInInput = _.groupBy(sortedInput, (slot) => slot.trayId)
    Object.keys(traysInInput).forEach((trayId, index) => {
      const slotInSameTray = sortedInput.filter((slot) => slot.trayId === trayId)
      const slotInSameTrayWithColId = slotInSameTray.map((slot) => {
        return { ...slot, colId: getRandomNumId() }
      })
      newTrays.push({ rowId: trayId, slots: slotInSameTrayWithColId })
    })
    newTrays.sort((a, b) => {
      return a.rowId.localeCompare(b.rowId)
    })
    return newTrays
  },
  catalogValidation(input: CatalogSaveInput['catalog']): string[] | null {
    const error: string[] | null = []
    const checkIfDuplicateExists = (w: string[]): boolean => {
      return new Set(w).size !== w.length
    }
    const isNumeric = (num: any): boolean => {
      return !isNaN(num)
    }
    if (input != null) {
      if (Object.keys(input.products).length > 0) {
        if (!checkIfDuplicateExists(Object.keys(input.products))) {
          _.pairs(input.products).forEach((pair) => {
            if (
              typeof pair[1].price === 'undefined' ||
              pair[1].price == null ||
              !isNumeric(pair[1].price) ||
              pair[1].price === ''
            ) {
              error.push(`Price for product [${pair[0]}] is missing / empty`)
            }
          })
        } else {
          error.push('Duplicate products in product list')
        }
      } else {
        error.push('Product list is empty')
      }

      if (Object.keys(input.slots).length > 0) {
        if (!checkIfDuplicateExists(Object.keys(input.slots))) {
          _.pairs(input.slots).forEach((pair) => {
            if (
              typeof pair[1].productCode === 'undefined' ||
              pair[1].productCode == null ||
              pair[1].productCode === ''
            ) {
              error.push('Product must be assigned to every slot')
            }
            if (typeof pair[1].capacity === 'undefined' || pair[1].capacity == null || !isNumeric(pair[1].capacity)) {
              error.push('Capacity is required and must be a number')
            }
          })
        } else {
          error.push('Duplicate slot ID')
        }
      } else {
        error.push('Slot list is empty')
      }
    } else {
      error.push('Missing catalog')
    }
    return error
  },
  catalogTraysValidation(input: CatalogTray[]): string[] | null {
    const error: string[] | null = []
    const checkIfDuplicateExists = (w: string[]): boolean => {
      return new Set(w).size !== w.length
    }
    const isNumeric = (num: any): boolean => {
      return !isNaN(num)
    }
    if (input != null) {
      const everySlotsId = _.compact(_.flatten(input.map((tray) => tray.slots.map((slot) => slot.slotId))))
      if (checkIfDuplicateExists(everySlotsId)) {
        error.push('Duplicate slot ID')
      }
      input.forEach((tray) => {
        if (tray.slots.length > 0) {
          tray.slots.forEach((slot) => {
            if (typeof slot.slotId === 'undefined' || slot.slotId == null || slot.slotId === '') {
              error.push('Slot ID is required')
            }
            if (typeof slot.productCode === 'undefined' || slot.productCode == null || slot.productCode === '') {
              error.push('Product must be assigned to every slot')
            }
            if (typeof slot.capacity === 'undefined' || slot.capacity == null || !isNumeric(slot.capacity)) {
              error.push('Capacity is required and must be a number')
            }
          })
        } else {
          error.push('Tray cannot be empty')
        }
      })
    } else {
      error.push('Missing catalog')
    }
    return _.uniq(error)
  },
  inverseCatalogTransform(inputCatalog: CatalogTray[], inputProducts: Product[]): CatalogSaveInput['catalog'] {
    const newSlotInfo: CatalogSaveInput['catalog'] = { products: {}, slots: {} }
    inputCatalog.forEach((tray, index) => {
      tray.slots.forEach((slot) => {
        if (typeof slot.slotId !== 'undefined' && typeof slot.capacity !== 'undefined') {
          const { colId, slotId, productCode, ...rest } = slot
          // Abandon random generated row ID
          const slotObj = { [slotId]: { ...rest, productCode: productCode, trayId: index.toString() } }
          newSlotInfo.slots = Object.assign({}, newSlotInfo.slots, slotObj)
          // newSlotInfo.slots = {...newSlotInfo.slots, ...slotObj}
        }
      })
    })
    inputProducts.forEach((product) => {
      const { code, price } = product
      const productObj = { [code]: { price: price } }
      newSlotInfo.products = Object.assign({}, newSlotInfo.products, productObj)
    })
    return newSlotInfo
  },
  inverseDraftCatalogTransform(
    inputCatalog: CatalogTray[],
    inputProducts: Product[]
  ): DraftCatalogSaveInput['catalog'] {
    const newSlotInfo: DraftCatalogSaveInput['catalog'] = { products: {}, slots: {} }
    inputCatalog.forEach((tray, index) => {
      tray.slots.forEach((slot) => {
        const { colId, slotId, productCode, ...rest } = slot
        if (typeof slotId !== 'undefined') {
          const slotObj = { [slotId]: { ...rest, productCode: productCode, trayId: index.toString() } }
          newSlotInfo.slots = Object.assign({}, newSlotInfo.slots, slotObj)
          // newSlotInfo.slots = {...newSlotInfo.slots, ...slotObj}
        }
      })
    })
    inputProducts.forEach((product) => {
      const { code, price } = product
      const productObj = { [code]: { price: price } }
      newSlotInfo.products = Object.assign({}, newSlotInfo.products, productObj)
    })
    return newSlotInfo
  },
  async getProductInfoByCodes(productCodes: string[], project: string): Promise<Product[]> {
    if (productCodes.length > 0) {
      const productCodeGroups = []
      let i,
        j,
        temparray,
        chunk = 10
      for (i = 0, j = productCodes.length; i < j; i += chunk) {
        temparray = productCodes.slice(i, i + chunk)
        productCodeGroups.push(temparray)
      }
      const promises = productCodeGroups.map(async (productCodeGroup) => {
        const products = await firebase
          .firestore()
          .collection('product')
          .doc(project)
          .collection('publish')
          .where('code', 'in', productCodeGroup)
          .get()
        return products.docs.map((product) => product.data()) as Product[]
      })

      const productGroups = await Promise.all(promises)
      return _.flatten(productGroups)
    } else {
      return []
    }
  },
  normalizeSlotId(input: string): string {
    const DIGITS_REGEX = /^\d+$/
    const trimmedSlotId = input.trim()
    let normalizedSlotId: string
    if (DIGITS_REGEX.test(trimmedSlotId)) {
      normalizedSlotId = parseInt(trimmedSlotId, 10).toString()
    } else {
      normalizedSlotId = trimmedSlotId
    }
    return normalizedSlotId
  }
}

export default catalogUtil
