/** Left side-menu **/

// ==================
// Library
// ==================
import { useState, useEffect, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { Layout, Menu as MenuAntd } from 'antd'
import { Link } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import _ from 'underscore'

// ==================
// Customization
// ==================
import './index.css'
import ImgLogo from '../../assets/freshup-blue-orange.png'
import Icon from '../Icon'

// ==================
// Types declaration
// ==================
import { RootState } from '../../store'
import { History as BrowseHistory } from 'history'
import { Menu, UserInfo } from '../../models/index.type'
import { Permission } from '../../protocol/firestore-v2/types'
import { menu as menuMap } from '../../config/menu'

const { Sider } = Layout
const { SubMenu, Item } = MenuAntd

interface Props {
  data: UserInfo | null
  collapsed: boolean
  history: BrowseHistory
  location: Location
  project: string
}

// ==================
// Component
// ==================
function MenuCom(props: Props): JSX.Element {
  const [chosedKey, setChosedKey] = useState<string[]>([])
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const [menu, setMenu] = useState<Menu[]>([])

  useEffect(() => {
    if (props.data && props.data.project) {
      let allPermissions: Permission[] = props.data.project[props.project].effectivePermissions.filter((permission) =>
        permission.toString()
      )
      allPermissions = [
        ...allPermissions,
        ...props.data.globalEffectivePermissions.filter((permission) => permission.toString()),
      ] 
      console.debug('allPermissons: ', allPermissions)
      const menus = _.flatten(allPermissions.map((menuCode) => menuMap.filter((item) => item.code === menuCode)))
      console.debug('menus permitted :', menus)
      setMenu(_.compact([...menus, menuMap.find((item) => item.url === '/home')]))
    }
  }, [props.data, props.project])

  useEffect(() => {
    const paths = props.location.pathname.split('/').filter((item) => item !== '')
    const pathWithoutParams = _.without(props.location.pathname.split('/'), props.project)
    const pathArrayWithoutParams = pathWithoutParams.map((item) => '/' + item)
    setChosedKey(pathArrayWithoutParams)
    setOpenKeys(getOpenSubMenus(paths))
  }, [props.location, props.project])

  const getOpenSubMenus = (paths: string[]): string[] => {
    console.debug('get open sub menus from paths: ', paths)
    const involvedSubMenus = paths.map(path => menuMap.find(menuTemp => '/' + path === menuTemp.url))
    console.debug('Involved submenus: ', involvedSubMenus)
    if (typeof involvedSubMenus === 'undefined' || involvedSubMenus.length < 1 ) {
      return []
    }
    const subMenusShouldBeOpen = involvedSubMenus.map(involvedSubMenu => {
      if (typeof involvedSubMenu === 'undefined') {
        return ''
      }
      return menuMap.find(menuTemp => menuTemp.id === involvedSubMenu.parent)?.url ?? ''
    })
    return subMenusShouldBeOpen
  }

  // ==================
  // Functions
  // ==================

  // Menu on selected
  const onClick = useCallback<Required<MenuAntd['props']>['onClick']>(
    (e) => {
      if (props.location.pathname !== `/${props.project}${e.key}`) {
        props.history.push({ pathname: `/${props.project}${e.key}` })
      }
    },
    // TODO Re-enable rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.project, props.location]
  )

  // 工具 - 递归将扁平数据转换为层级数据
  const dataToJson = useCallback((one: Menu | null, data: Menu[]): Menu[] | null => {
    let kids: Menu[]
    if (one == null) {
      kids = data.filter((item: Menu) => item.parent == null)
    } else {
      kids = data.filter((item: Menu) => item.parent === one.id)
    }
    kids.forEach((item: Menu) => {
      const children = dataToJson(item, data)
      if (children == null) {
        delete item.children
      } else {
        item.children = children
      }
    })
    return kids.length > 0 ? kids : null
  }, [])

  // 构建树结构
  const makeTreeDom = useCallback((data: Menu[]): JSX.Element[] => {
    return data.map((item: Menu) => {
      if (item.children != null) {
        return (
          <SubMenu
            key={item.url}
            title={
              item.parent == null && item.icon !== '' ? (
                <span>
                  {item.icon && <Icon type={item.icon} />}
                  <span>{item.title}</span>
                </span>
              ) : (
                item.title
              )
            }
          >
            {makeTreeDom(item.children)}
          </SubMenu>
        )
      } else {
        return (
          <Item key={item.url}>
            {item.parent == null && item.icon && item.icon !== ''? <Icon type={item.icon} /> : null}
            <span>{item.title}</span>
          </Item>
        )
      }
    })
  }, [])

  useEffect(() => {console.debug('chosedKey', chosedKey)},[chosedKey])

  // ==================
  // 计算属性 memo
  // ==================

  /** 处理原始数据，将原始数据处理为层级关系 **/
  const treeDom: JSX.Element[] = useMemo(() => {
    const d: Menu[] = cloneDeep(menu)
    // 按照sort排序
    d.sort((a, b) => {
      return a.sorts - b.sorts
    })
    const sourceData: Menu[] = dataToJson(null, d) ?? []
    const newTreeDom = makeTreeDom(sourceData)
    return newTreeDom
  }, [menu, dataToJson, makeTreeDom])

  return (
    <Sider
      width={256}
      className='sider notPrintable'
      trigger={null}
      collapsible
      collapsed={props.collapsed}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0
      }}
    >
      <div className={props.collapsed ? 'menuLogo hide' : 'menuLogo'}>
        <Link to={`/${props.project}`}>
          <img src={ImgLogo} alt='logo' />
        </Link>
      </div>
      <MenuAntd
        theme='dark'
        mode='inline'
        selectedKeys={chosedKey}
        {...(props.collapsed ? {} : { openKeys })}
        onOpenChange={(keys: string[]) => setOpenKeys(keys)}
        onClick={onClick}
      >
        {treeDom}
      </MenuAntd>
    </Sider>
  )
}

const mapState = (state: RootState) =>
  ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    userinfo: state.app.userinfo,
    project: state.app.project,
  } as const)

export default connect(mapState)(MenuCom)
