/* Footer 页面底部 */
import React from 'react'
import { Layout } from 'antd'
import './index.css'

const { Footer } = Layout

interface Props {
  className?: string
}

export default function FooterCom (props: Props): JSX.Element {
  return (
    <Footer className={`footer ${props.className ?? ''}`}>
      Copyright © 2020. All Rights Reserved
      By <a href='https://www.freshup.com.hk/' target='_blank' rel='noreferrer'>Fresh Up Group</a> Ltd.
    </Footer>
  )
}
