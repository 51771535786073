import { Button, Input, Popover, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SlotChange, SlotInfoWithProductWithImageUrl } from '.'
import { RootState } from '../../../../store'
import permissionUtil from '../../../../util/permission'
import { SyncOutlined, VerticalAlignTopOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'

interface SlotProps {
  slot: SlotInfoWithProductWithImageUrl
  disabled?: boolean
  updateChanges: (slotId: string, stock: number) => void
  getChange: (slotId: string) => SlotChange | null
  warning?: boolean
}

export const Slot = (props: SlotProps): JSX.Element => {
  const project = useSelector((state: RootState) => state.app.project)
  const userInfo = useSelector((state: RootState) => state.app.userinfo)
  const [allowToViewProduct, setAllowToViewProduct] = useState(false)
  const slot = props.slot
  const slotChange = props.getChange(slot.slotId)
  //   const [slotLoading, setSlotLoading] = useState(false)

  const getChangeLabel = (change: SlotChange | null): string => {
    if (!change) {
      return '+0'
    }
    return change.to - change.from > 0
      ? `+${change.to - change.from}`
      : change.to - change.from < 0
        ? `${change.to - change.from}`
        : '+0'
  }

  const getChangeClass = (change: SlotChange | null): string => {
    if (!change) {
      return 'noChange'
    }
    return change.to > change.from ? 'positiveChange' : change.to < change.from ? 'negativeChange' : 'noChange'
  }

  const goToProduct = (code: string) => {
    window.open(`${window.location.origin}/#/${project}/product/publish/${code}`, '_blank')
  }

  useEffect(() => {
    setAllowToViewProduct(permissionUtil.isPermitted(userInfo, 'product.read', project))
  }, [userInfo, project])

  useEffect(() => {
    if (props.warning) {
      console.warn(`Slot[${props.slot.slotId}]'s stock is not as expected!`)
      // Reset change
      props.updateChanges(props.slot.slotId, props.slot.stock)
    }
  }, [props.warning])

  return (
    <div className={`slot${props.warning ? ' warning' : ''}`}>
      <div className='slotId'>{slot.slotId}</div>
      <div className='slotActions'>
        <Tooltip title='Fill up' placement='right'>
          <VerticalAlignTopOutlined
            className='slotButton'
            onClick={() => props.updateChanges(props.slot.slotId, props.slot.capacity)}
          />
        </Tooltip>
        <Tooltip title='Empty out' placement='right'>
          <VerticalAlignBottomOutlined
            className='slotButton'
            onClick={() => props.updateChanges(props.slot.slotId, 0)}
          />
        </Tooltip>
        {slotChange && slotChange.from !== slotChange.to && (
          <Tooltip title='Reset changes' placement='right'>
            <SyncOutlined
              className='slotButton'
              onClick={() => props.updateChanges(props.slot.slotId, props.slot.stock)}
            />
          </Tooltip>
        )}
      </div>

      {slot?.imageUrl !== '' ? (
        <img
          className='image'
          src={slot.imageUrl}
          style={{ cursor: allowToViewProduct ? 'pointer' : 'initial', background: 'white' }}
          onClick={allowToViewProduct ? () => goToProduct(slot.productCode) : undefined}
        />
      ) : (
        <div
          className='placeholder'
          style={{ cursor: allowToViewProduct ? 'pointer' : 'initial', background: 'white' }}
          onClick={allowToViewProduct ? () => goToProduct(slot.productCode) : undefined}></div>
      )}
      <Popover content={slot?.product?.name.en ?? slot?.product?.name['zh-CHT'] ?? 'Unknown'}>
        {allowToViewProduct ? (
          <Button
            type='text'
            onClick={allowToViewProduct ? () => goToProduct(slot.productCode) : undefined}
            className='productCode'>
            {slot.productCode}
          </Button>
        ) : (
          <div> {slot.productCode}</div>
        )}
      </Popover>
      <Input
        type='tel'
        prefix={
          <span className={`changes ${getChangeClass(props.getChange(slot.slotId))}`}>
            {getChangeLabel(props.getChange(slot.slotId))}
          </span>
        }
        suffix={`/${slot.capacity}`}
        style={{ textAlign: 'right' }}
        min={0}
        max={slot.capacity}
        disabled={props.disabled ?? false}
        value={props.getChange(slot.slotId)?.to ?? slot.stock}
        onChange={(e) => {
          const newValue = Math.min(parseInt(e.target.value, 10) || 0, slot.capacity)
          props.updateChanges(slot.slotId, newValue)
        }}
      />
    </div>
  )
}
