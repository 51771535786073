/** 全局唯一数据中心 **/

import { init, Models, RematchDispatch, RematchRootState } from '@rematch/core'

import app from '../models/app'
import sys from '../models/sys'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import { FirebaseReducer, firebaseReducer, FirestoreReducer } from 'react-redux-firebase'
import { firestoreReducer, createFirestoreInstance } from 'redux-firestore'

const config = {
  // userProfile: 'portal/console/signinUsers',
  // useFirestoreForProfile: true,
  enableLogging: false
}

export interface RootModel extends Models<RootModel> {
  app: typeof app
  sys: typeof sys
}

export type Dispatch = RematchDispatch<RootModel>
// Quick and dirty way to get pass TypeScript checking by adding `firebase` and `firestore`
// properties, we should use the proper way in the future
export type RootState = RematchRootState<RootModel> & {
  firebase: FirebaseReducer.Reducer
  firestore: FirestoreReducer.Reducer
}

const rootModel: RootModel = { app, sys }

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG ?? '{}')

firebase.initializeApp(firebaseConfig)
firebase.firestore()

const store = init({
  models: rootModel,
  redux: {
    reducers: {
      firebase: firebaseReducer,
      firestore: firestoreReducer
    }
  }
})

export const rrfProps = {
  firebase,
  config,
  dispatch: store.dispatch,
  createFirestoreInstance
}

export type Store = typeof store

export default store
