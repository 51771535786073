import firebase from 'firebase/app'
import 'firebase/functions'

import {
  AddProductApiRequest,
  AddProductApiResponse,
  GetLastStockChangeResultApiRequest,
  GetLastStockChangeResultApiResponse,
  GetStockApiRequest,
  GetStockApiResponse,
  ExportTransactionsApiRequest,
  ExportTransactionsApiResponse,
  GetDailySalesApiRequest,
  GetDailySalesApiResponse,
  ListMachinesApiRequest,
  ListMachinesApiResponse,
  SaveCatalogApiRequest,
  SaveCatalogApiResponse,
  SaveDraftCatalogApiRequest,
  SaveDraftCatalogApiResponse,
  SubmitStockChangeApiRequest,
  SubmitStockChangeApiResponse,
  UpdateProductApiRequest,
  UpdateProductApiResponse,
  CopyCatalogApiRequest,
  CopyCatalogApiResponse
} from './protocol/functions-v2/console-api/types'

import {
  AddMachineApiRequest,
  AddMachineApiResponse,
  AddMediaApiRequest,
  AddMediaApiResponse,
  CopyAdPlaylistApiRequest,
  CopyAdPlaylistApiResponse,
  SaveAdPlaylistApiRequest,
  SaveAdPlaylistApiResponse,
  TrashMachinesApiRequest,
  TrashMachinesApiResponse,
  TrashMediaApiRequest,
  TrashMediaApiResponse,
  TrashProductsApiRequest,
  TrashProductsApiResponse,
  UpdateMachineApiRequest,
  UpdateMachineApiResponse,
  GetMachineSalesApiRequest,
  GetMachineSalesApiResponse,
  GetMachineProductSalesApiRequest,
  GetMachineProductSalesApiResponse
} from './protocol/functions-v2/console-api/types'

export interface HttpsCallableResult<T> {
  readonly data: T
}

const app = firebase.app()
const consoleApi = app.functions('asia-east2').httpsCallable('consoleApi')

export async function addProduct(data: AddProductApiRequest): Promise<HttpsCallableResult<AddProductApiResponse>> {
  return await consoleApi(data)
}

export async function updateProduct(
  data: UpdateProductApiRequest
): Promise<HttpsCallableResult<UpdateProductApiResponse>> {
  return await consoleApi(data)
}

export async function trashProduct(
  data: TrashProductsApiRequest
): Promise<HttpsCallableResult<TrashProductsApiResponse>> {
  return await consoleApi(data)
}

export async function addMachine(data: AddMachineApiRequest): Promise<HttpsCallableResult<AddMachineApiResponse>> {
  return await consoleApi(data)
}

export async function updateMachine(
  data: UpdateMachineApiRequest
): Promise<HttpsCallableResult<UpdateMachineApiResponse>> {
  return await consoleApi(data)
}

export async function trashMachine(
  data: TrashMachinesApiRequest
): Promise<HttpsCallableResult<TrashMachinesApiResponse>> {
  return await consoleApi(data)
}

export async function saveCatalog(data: SaveCatalogApiRequest): Promise<HttpsCallableResult<SaveCatalogApiResponse>> {
  return await consoleApi(data)
}


export async function saveDraftCatalog(
  data: SaveDraftCatalogApiRequest
): Promise<HttpsCallableResult<SaveDraftCatalogApiResponse>> {
  return await consoleApi(data)
}

export async function addMedia(data: AddMediaApiRequest): Promise<HttpsCallableResult<AddMediaApiResponse>> {
  return await consoleApi(data)
}

export async function trashMedia(data: TrashMediaApiRequest): Promise<HttpsCallableResult<TrashMediaApiResponse>> {
  return await consoleApi(data)
}

export async function saveAdPlaylist(
  data: SaveAdPlaylistApiRequest
): Promise<HttpsCallableResult<SaveAdPlaylistApiResponse>> {
  return await consoleApi(data)
}

export async function copyAdPlaylist(
  data: CopyAdPlaylistApiRequest
): Promise<HttpsCallableResult<CopyAdPlaylistApiResponse>> {
  return await consoleApi(data)
}

export async function copyCatalog(
  data: CopyCatalogApiRequest
): Promise<HttpsCallableResult<CopyCatalogApiResponse>> {
  return await consoleApi(data)
}

export async function getMachineSales(
  data: GetMachineSalesApiRequest
): Promise<HttpsCallableResult<GetMachineSalesApiResponse>> {
  return await consoleApi(data)
}

export async function getMachineProductSales(
  data: GetMachineProductSalesApiRequest
): Promise<HttpsCallableResult<GetMachineProductSalesApiResponse>> {
  return await consoleApi(data)
}

export async function getStock(data: GetStockApiRequest): Promise<HttpsCallableResult<GetStockApiResponse>> {
  return await consoleApi(data)
}

export async function submitStockChange(
  data: SubmitStockChangeApiRequest
): Promise<HttpsCallableResult<SubmitStockChangeApiResponse>> {
  return await consoleApi(data)
}

export async function getLastStockChangeResult(
  data: GetLastStockChangeResultApiRequest
): Promise<HttpsCallableResult<GetLastStockChangeResultApiResponse>> {
  return await consoleApi(data)
}

export async function listMachines(
  data: ListMachinesApiRequest
): Promise<HttpsCallableResult<ListMachinesApiResponse>> {
  return await consoleApi(data)
}

export async function getDailySales(
  data: GetDailySalesApiRequest
): Promise<HttpsCallableResult<GetDailySalesApiResponse>> {
  return await consoleApi(data)
}

export async function exportTransactions(
  data: ExportTransactionsApiRequest
): Promise<HttpsCallableResult<ExportTransactionsApiResponse>> {
  return await consoleApi(data)
}
