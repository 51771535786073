import axios from '../util/axios' // 自己写的工具函数，封装了请求数据的通用接口
import { message } from 'antd'
import { Dispatch } from '../store'
import {
  UserInfo,
  AppState,
  Res
} from './index.type'

const defaultState: AppState = {
  userinfo: null,
  project: ''
}

const appModel = {
  state: defaultState,
  reducers: {
    reducerUserInfo (state: AppState, payload: UserInfo) {
      console.debug('reducerUserInfo', payload)
      return {
        ...state,
        userinfo: payload,
      }
    },
    reducerLogout (state: AppState) {
      return {
        ...state,
        userinfo: null
      }
    },
    reducerProject (state: AppState, payload: string) {
      return {
        ...state,
        project: payload
      }
    }
  },

  effects: (dispatch: Dispatch) => ({
    /**
     * Login
     * @param { username, password } params
     * */
    async setProject (params: string) {
      try {
        dispatch.app.reducerProject(params)
        return 'success'
      } catch (err) {
        message.error('Fail to set project').then(() => {}, () => {})
      }
    },
    async onLogin (params: { username: string, password: string }) {
      try {
        const res: Res = await axios.post('/api/login', params)
        return res
      } catch (err) {
        message.error('Internet error. Please retry.').then(() => {}, () => {})
      }
    },
    /**
     * Logout
     * @param null
     * **/
    async onLogout () {
      try {
        // 同 dispatch.app.reducerLogout();

        dispatch({ type: 'app/reducerLogout', payload: null })
        sessionStorage.removeItem('userinfo')
        return 'success'
      } catch (err) {
        message.error('Internet error. Please retry.').then(() => {}, () => {})
      }
    },
    /**
     * 设置用户信息
     * @param: {*} params
     * **/
    async setUserInfo (params: UserInfo) {
      dispatch.app.reducerUserInfo(params)
      return 'success'
    }
  })
}

export default appModel
