import 'firebase/firestore'
import { UserInfo } from '../models/index.type'
import { Permission } from '../protocol/firestore-v2/types'

const permissionUtil = {
  isPermitted(userinfo: UserInfo | null, actionCode: Permission, projectName: string): boolean {
    try {
      if (userinfo === null || typeof userinfo === 'undefined') {
        return false
      }
      if (
        typeof projectName !== 'undefined' &&
        JSON.parse(JSON.stringify(userinfo.project[projectName].effectivePermissions)).includes(actionCode)
      ) {
        return true
      }
      if (JSON.parse(JSON.stringify(userinfo.globalEffectivePermissions)).includes(actionCode)) {
        return true
      }
      return false
    } catch (e) {
      console.debug(e)
      return false
    }
  },
  isGrantedAllMachine(userinfo: UserInfo | null, projectName: string): boolean {
    try {
      if (userinfo === null || typeof userinfo === 'undefined') {
        return false
      }
      if (
        userinfo.project[projectName].grantedMachineIds === 'ALL'
      ) {
        return true
      }
      return false
    } catch (e) {
      console.debug(e)
      return false
    }
  },
}

export default permissionUtil
