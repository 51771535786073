import './index.css'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export default function LoadingComponent (): JSX.Element {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: '3em', color: 'grey' }} spin />
  )
  return (
    <div className='loading'>
      <Spin indicator={antIcon} />
    </div>
  )
}
